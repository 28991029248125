@import "~@/styles/variables.scss";
















































































































.img {
    width: 100%;
    max-width: 50px;
}
